const LinkedinIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.5627 9.42811C25.3084 8.48288 24.5632 7.73776 23.6181 7.48324C21.8915 7.01083 14.9851 7.01083 14.9851 7.01083C14.9851 7.01083 8.07891 7.01083 6.35234 7.46533C5.42542 7.71958 4.66199 8.48301 4.40774 9.42811C3.95337 11.1545 3.95337 14.735 3.95337 14.735C3.95337 14.735 3.95337 18.3334 4.40774 20.0418C4.66226 20.9869 5.40724 21.732 6.35247 21.9865C8.09709 22.4591 14.9853 22.4591 14.9853 22.4591C14.9853 22.4591 21.8915 22.4591 23.6181 22.0046C24.5633 21.7502 25.3084 21.0051 25.5629 20.06C26.0172 18.3334 26.0172 14.7531 26.0172 14.7531C26.0172 14.7531 26.0354 11.1545 25.5627 9.42811ZM12.7862 18.0427V11.4273L18.5293 14.735L12.7862 18.0427Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedinIcon;
