const FacebookIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 3C8.373 3 3 8.373 3 15C3 21.016 7.432 25.984 13.206 26.852V18.18H10.237V15.026H13.206V12.927C13.206 9.452 14.899 7.927 17.787 7.927C19.17 7.927 19.902 8.03 20.248 8.076V10.829H18.278C17.052 10.829 16.624 11.992 16.624 13.302V15.026H20.217L19.73 18.18H16.624V26.877C22.481 26.083 27 21.075 27 15C27 8.373 21.627 3 15 3Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookIcon;
