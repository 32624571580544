const InstagramIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3.75C6.54875 3.75 3.75 6.54875 3.75 10V20C3.75 23.4513 6.54875 26.25 10 26.25H20C23.4513 26.25 26.25 23.4513 26.25 20V10C26.25 6.54875 23.4513 3.75 20 3.75H10ZM22.5 6.25C23.19 6.25 23.75 6.81 23.75 7.5C23.75 8.19 23.19 8.75 22.5 8.75C21.81 8.75 21.25 8.19 21.25 7.5C21.25 6.81 21.81 6.25 22.5 6.25ZM15 8.75C18.4513 8.75 21.25 11.5487 21.25 15C21.25 18.4513 18.4513 21.25 15 21.25C11.5487 21.25 8.75 18.4513 8.75 15C8.75 11.5487 11.5487 8.75 15 8.75ZM15 11.25C14.0054 11.25 13.0516 11.6451 12.3483 12.3483C11.6451 13.0516 11.25 14.0054 11.25 15C11.25 15.9946 11.6451 16.9484 12.3483 17.6517C13.0516 18.3549 14.0054 18.75 15 18.75C15.9946 18.75 16.9484 18.3549 17.6517 17.6517C18.3549 16.9484 18.75 15.9946 18.75 15C18.75 14.0054 18.3549 13.0516 17.6517 12.3483C16.9484 11.6451 15.9946 11.25 15 11.25Z"
        fill="white"
      />
    </svg>
  );
};

export default InstagramIcon;
