
const BookCardItem = ({ book }) => {

  const downloadBook = async () => {
    window.open(book.link, "_blank");
  };

  return (
    <div className="w-full relative bg-card p-3 rounded-lg flex flex-row items-end">
      <img
        src={book.src}
        alt={book.title}
        className="grow-0 w-[30%] z-20"
      />
      <div className=" grow-1  w-full h-full flex flex-col justify-between  pb-5">
        <div className="px-2">
          <p className="font-bold text-text-dark-3 text-xl line-clamp-2">
            {book.title}
          </p>
          <p className=" text-text-dark-3 line-clamp-2 ">{book.subTitle}</p>
        </div>

        <div
          className={`relative -left-2 z-10 w-[50%] rounded-md pl-4 pr-1 text-[1rem]  text-white py-1 flex items-center justify-center `}
          style={{ backgroundColor: `${book.color}` }}
        >
          <span className="text-ellipsis overflow-hidden whitespace-nowrap inline-block" >{book.level}</span>
          
        </div>
      </div>
      <div className="absolute bottom-3 right-3">
        <button className="underline text-text-dark-3 text-xl" onClick={downloadBook}>Download</button>
      </div>
    </div>
  );
};

export default BookCardItem;
