import { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-cards";
import { Autoplay, EffectCards, Navigation } from "swiper/modules";
// import "./swipper.css"

import book1 from "../../assets/images/books/book-1.png";
import book2 from "../../assets/images/books/book-2.png";
import book3 from "../../assets/images/books/book-3.png";
import book4 from "../../assets/images/books/book-4.png";
import book5 from "../../assets/images/books/book-5.png";
import book6 from "../../assets/images/books/book-6.png";
import book7 from "../../assets/images/books/book-7.png";
import book8 from "../../assets/images/books/book-8.png";
import book9 from "../../assets/images/books/book-9.png";

const BooksGallery = ({ className, isAnimating }) => {
  const booksList = [
    { title: "book 9", src: book9 },
    { title: "book 8", src: book8 },
    { title: "book 7", src: book7 },
    { title: "book 5", src: book5 },
    { title: "book 6", src: book6 },
    { title: "book 4", src: book4 },
    { title: "book 1", src: book1 },
    { title: "book 2", src: book2 },
    { title: "book 3", src: book3 },
  ];
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const swiperRef = useRef(null);

  const handlePrevClick = () => {
    swiperRef.current.swiper.slidePrev();
  };

  const handleNextClick = () => {
    swiperRef.current.swiper.slideNext();
  };

  return (
    <>
      <div className={`container ${className}`}>
        <div className="hidden md:flex flex-row-reverse justify-between items-end ">
          {booksList.map((book, index) => (
            <img
              src={book.src}
              alt={book.title}
              key={index}
              className={`shadow-2xl hover:!w-[20%] hover:!scale-105 transition-all ease-in-out hover:!z-[1000] duration-300 animate__animated  ${
                isAnimating ? "animate__fadeOutDown" : "animate__fadeInUp"
              } `}
              style={{
                width: `${20 - index}%`,

                marginLeft: `-${index !== 0 ? 11 : 0}%`,
                zIndex: `${99 - index}`,
                animationDelay: `${index / 20}s`,
              }}
            />
          ))}
        </div>
        <div
          className={`block md:hidden relative w-full min-w-full  animate__animated overflow-hidden py-4 ${
            isAnimating ? "animate__fadeOutDown " : "animate__fadeInUp"
          } `}
          dir="ltr"
        >
          <div className="!max-w-full !overflow-hidden">
            <Swiper
              ref={swiperRef}
              effect={"cards"}
              grabCursor={true}
              modules={[EffectCards, Navigation, Autoplay]}
              autoplay={{ delay: 1500, disableOnInteraction: false }}
              navigation
              className="my-swiper "
              cardsEffect={{
                perSlideOffset: 10, // Offset distance per slide (in px)
                perSlideRotate: 4, // Rotate angle per slide (in degrees)
                rotate: true, // Enables cards rotation
                slideShadows: true, // Enables slide shadows
              }}
              // Add additional Swiper options as needed (e.g., navigation, pagination)
            >
              {booksList.map((book) => (
                <SwiperSlide key={book.title}>
                  <img src={book.src} alt={book.title} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>

          <button
            className="swiper-button-custom-prev absolute z-50 left-4 top-[50%] transform -translate-y-1/2 bg-primary text-white p-2 rounded-full shadow-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
            onClick={handlePrevClick}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </button>
          <button
            className="swiper-button-custom-next absolute z-50 right-4 top-[50%] transform -translate-y-1/2 bg-primary text-white p-2 rounded-full shadow-md hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary-dark"
            onClick={handleNextClick}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default BooksGallery;
