const ElmoaaserIconWithLogo = () => {
  return (
    <svg width="100%"  viewBox="0 0 186 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clipPath="url(#clip0_10576_250)">
<path d="M30.616 19.4073C32.5101 11.424 27.5398 3.42482 19.5146 1.54059C11.4893 -0.343635 3.44803 4.60062 1.5539 12.5839C-0.340237 20.5672 4.63003 28.5664 12.6553 30.4506C20.6806 32.3348 28.7218 27.3906 30.616 19.4073Z" fill="white"/>
<path d="M16.0841 1.72175C24.0005 1.72175 30.4375 8.12503 30.4375 16C30.4375 23.875 24.0005 30.2782 16.0841 30.2782C8.16776 30.2782 1.73081 23.8709 1.73081 16C1.73081 8.12913 8.16776 1.72175 16.0841 1.72175ZM16.0841 0.573914C7.53313 0.573914 0.576937 7.49372 0.576937 16C0.576937 24.5063 7.53313 31.4261 16.0841 31.4261C24.6352 31.4261 31.5914 24.5063 31.5914 16C31.5914 7.49372 24.6352 0.573914 16.0841 0.573914Z" fill="#D1D3D4"/>
<path d="M45.7593 30.8522C37.8181 30.8522 31.3523 24.4243 31.3523 16.5206V1.14783H170.439C178.38 1.14783 184.846 7.5757 184.846 15.4794V30.8522H45.7593Z" fill="white"/>
<path d="M170.439 1.72176C178.063 1.72176 184.269 7.89137 184.269 15.4794V30.2783H45.7593C38.1355 30.2783 31.9293 24.1086 31.9293 16.5206V1.72176H170.439ZM170.439 0.573924H30.7795V16.5206C30.7795 24.74 37.5008 31.4261 45.7634 31.4261H185.423V15.4753C185.423 7.25596 178.702 0.569824 170.439 0.569824V0.573924Z" fill="#D1D3D4"/>
<path d="M8.18013 16.0041C8.18013 16.0041 8.18013 16.0206 8.18013 16.0288C8.18426 16.0206 8.19253 16.0123 8.19667 16.0041H8.18013Z" stroke="#18171C" strokeWidth="0.05" strokeMiterlimit="10"/>
<path d="M23.9676 16.0042H23.9841C23.9841 16.0042 23.9841 15.9877 23.9841 15.9795C23.98 15.9877 23.9717 15.996 23.9676 16.0042Z" stroke="#18171C" strokeWidth="0.05" strokeMiterlimit="10"/>
<path d="M23.9841 16.0288C23.9841 16.0288 23.9841 16.0123 23.9841 16.0041H23.9676C23.9676 16.0041 23.98 16.0206 23.9841 16.0288Z" stroke="#18171C" strokeWidth="0.05" strokeMiterlimit="10"/>
<path d="M8.20079 16.0042C8.20079 16.0042 8.18839 15.9877 8.18425 15.9795C8.18425 15.9877 8.18425 15.996 8.18425 16.0042H8.20079Z" stroke="#18171C" strokeWidth="0.05" strokeMiterlimit="10"/>
<path d="M9.38345 20.1691C10.7805 22.3869 13.2572 23.8627 16.0841 23.8627C18.9111 23.8627 21.3878 22.3869 22.7848 20.1691H9.38345Z" fill="white"/>
<path d="M16.0841 4.41504C12.7008 4.41504 9.65131 5.84983 7.52489 8.1455H24.6434C22.517 5.85393 19.4675 4.41504 16.0841 4.41504Z" fill="#231F20"/>
<path d="M25.4676 9.13348C25.2121 8.78913 24.936 8.45707 24.6434 8.14142H16.0841C18.9111 8.14142 21.3878 9.61721 22.7848 11.835H26.9594C26.5844 10.8634 26.0775 9.95746 25.4676 9.13348Z" fill="white"/>
<path d="M7.52489 8.14551C7.2323 8.46116 6.9562 8.78912 6.7007 9.13757C6.0908 9.96155 5.58804 10.8716 5.20891 11.8391H9.38345C10.7805 9.6213 13.2572 8.14551 16.0841 8.14551H7.52489Z" fill="white"/>
<path d="M22.7848 11.8391C21.3878 9.6213 18.9111 8.14551 16.0841 8.14551C13.2572 8.14551 10.7805 9.6213 9.38345 11.8391H22.7848Z" fill="#231F20"/>
<path d="M8.18013 15.9795C8.18013 15.9795 8.19249 15.9959 8.19661 16.0041H11.8931C11.8931 13.7043 13.7682 11.8391 16.08 11.8391H9.38345C8.62931 13.0402 8.18837 14.4586 8.18425 15.9795H8.18013Z" fill="white"/>
<path d="M22.7848 11.8391H16.0841C18.396 11.8391 20.2711 13.7043 20.2711 16.0041H23.9676C23.9676 16.0041 23.9799 15.9877 23.984 15.9795C23.9799 14.4586 23.539 13.0402 22.7848 11.8391Z" fill="white"/>
<path d="M23.9676 16.0041H20.2711C20.2711 18.3039 18.396 20.1691 16.0841 20.1691H22.7848C23.539 18.968 23.9799 17.5496 23.984 16.0287C23.9799 16.0205 23.9717 16.0123 23.9676 16.0041Z" fill="white"/>
<path d="M11.8972 16.0041H8.20073C8.20073 16.0041 8.18837 16.0205 8.18425 16.0287C8.18837 17.5496 8.62931 18.968 9.38345 20.1691H16.0841C13.7723 20.1691 11.8972 18.3039 11.8972 16.0041Z" fill="white"/>
<path d="M11.8972 16.0041H20.2711C20.2711 13.7043 18.396 11.8391 16.0841 11.8391C13.7723 11.8391 11.8972 13.7043 11.8972 16.0041Z" fill="#231F20"/>
<path d="M20.2711 16.0041H11.8972C11.8972 18.3039 13.7723 20.1691 16.0841 20.1691C18.396 20.1691 20.2711 18.3039 20.2711 16.0041Z" fill="#EE3135"/>
<path d="M26.9553 11.8391H22.7807C23.5349 13.0402 23.9758 14.4586 23.9799 15.9795C23.9799 15.9877 23.9799 15.9959 23.9799 16.0041C23.9799 16.0123 23.9799 16.0205 23.9799 16.0287C23.9758 17.5496 23.5349 18.968 22.7807 20.1691C21.3837 22.3869 18.907 23.8627 16.08 23.8627C13.253 23.8627 10.7763 22.3869 9.37933 20.1691C8.62519 18.968 8.18425 17.5496 8.18013 16.0287C8.18013 16.0205 8.18013 16.0123 8.18013 16.0041C8.18013 15.9959 8.18013 15.9877 8.18013 15.9795C8.18425 14.4586 8.62519 13.0402 9.37933 11.8391H5.20891C4.70615 13.1304 4.43417 14.5365 4.43417 16.0041C4.43417 17.4717 4.71027 18.8778 5.20891 20.1691C5.58392 21.1406 6.0908 22.0466 6.7007 22.8706C6.9562 23.2149 7.2323 23.547 7.52489 23.8627C9.65544 26.1542 12.7008 27.5931 16.0841 27.5931C19.4675 27.5931 22.517 26.1583 24.6434 23.8627C24.936 23.547 25.2121 23.219 25.4676 22.8706C26.0775 22.0466 26.5803 21.1365 26.9594 20.1691C27.4621 18.8778 27.7341 17.4717 27.7341 16.0041C27.7341 14.5365 27.458 13.1304 26.9594 11.8391H26.9553Z" fill="#EE3135"/>
<path d="M34.6326 4.41095H170.439C176.579 4.41095 181.566 9.37124 181.566 15.4794V27.589H45.7593C39.619 27.589 34.6326 22.6287 34.6326 16.5206V4.41095Z" fill="#EE3135"/>
<path d="M48.7346 24.3382H45.6892C41.3375 24.3382 37.7934 20.8168 37.7934 16.4837V7.29697H48.7346V10.8635H41.3787V16.4878C41.3787 18.8532 43.3114 20.7758 45.6892 20.7758H48.7346V24.3423V24.3382Z" fill="white"/>
<path d="M162.094 24.3382H157.207C152.484 24.3382 148.639 20.5176 148.639 15.8196C148.639 11.1217 152.48 7.30106 157.207 7.30106H162.094V10.8675H157.207C154.458 10.8675 152.225 13.0894 152.225 15.8237C152.225 18.558 154.458 20.7799 157.207 20.7799H162.094V24.3464V24.3382Z" fill="white"/>
<path d="M162.094 14.1389H156.498C155.567 14.1389 154.808 14.8931 154.808 15.8196C154.808 16.7461 155.567 17.5004 156.498 17.5004H162.094V14.1389Z" fill="white"/>
<path d="M59.5233 24.3382H50.9063V7.54291H54.4916V20.7758H59.5233V24.3382Z" fill="white"/>
<path d="M93.035 24.3382C88.3124 24.3382 84.4675 20.5176 84.4675 15.8196C84.4675 11.1217 88.3083 7.30106 93.035 7.30106C97.7618 7.30106 101.603 11.1217 101.603 15.8196C101.603 20.5176 97.7618 24.3382 93.035 24.3382ZM93.035 10.8634C90.2863 10.8634 88.0528 13.0853 88.0528 15.8196C88.0528 18.5539 90.2863 20.7758 93.035 20.7758C95.7837 20.7758 98.0173 18.5539 98.0173 15.8196C98.0173 13.0853 95.7837 10.8634 93.035 10.8634Z" fill="white"/>
<path d="M126.299 24.3382H117.332V20.7717H126.299C127.177 20.7717 127.894 20.0625 127.894 19.1852C127.894 18.308 127.177 17.5988 126.299 17.5988H121.672C118.816 17.5988 116.492 15.2867 116.492 12.4458C116.492 9.60491 118.816 7.29285 121.672 7.29285H128.71V10.8593H121.672C120.794 10.8593 120.077 11.5685 120.077 12.4458C120.077 13.3231 120.794 14.0323 121.672 14.0323H126.299C129.155 14.0323 131.48 16.3443 131.48 19.1852C131.48 22.0261 129.155 24.3382 126.299 24.3382Z" fill="white"/>
<path d="M142.499 24.3382H133.532V20.7717H142.499C143.377 20.7717 144.094 20.0625 144.094 19.1852C144.094 18.308 143.377 17.5988 142.499 17.5988H137.871C135.015 17.5988 132.691 15.2867 132.691 12.4458C132.691 9.60491 135.015 7.29285 137.871 7.29285H144.91V10.8593H137.871C136.993 10.8593 136.276 11.5685 136.276 12.4458C136.276 13.3231 136.993 14.0323 137.871 14.0323H142.499C145.355 14.0323 147.679 16.3443 147.679 19.1852C147.679 22.0261 145.355 24.3382 142.499 24.3382Z" fill="white"/>
<path d="M63.5454 14.0364H59.5233V17.6029H63.5454V14.0364Z" fill="white"/>
<path d="M48.7346 14.0364H44.7125V17.6029H48.7346V14.0364Z" fill="white"/>
<path d="M180.437 23.8709L172.343 15.8196H167.777V10.8634H169.941C172.566 10.8634 174.704 12.991 174.704 15.6023V15.8196H178.29V15.6023C178.29 11.0233 174.544 7.29694 169.941 7.29694H164.192V23.8709H167.777V16.3197L175.368 23.8709H180.437Z" fill="white"/>
<path d="M107.092 7.54291L101.359 23.8709H105.159L108.452 14.4832L111.752 23.8709H115.548L109.803 7.54291H107.092Z" fill="white"/>
<path d="M80.2641 7.54291L74.0992 13.36L67.9342 7.54291H65.4493V23.8709H69.0345V13.4953L74.0992 18.2752L79.168 13.4953V23.8709H82.7532L82.7491 7.54291H80.2641Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_10576_250">
<rect width="186" height="32" fill="white"/>
</clipPath>
</defs>
</svg>

  );
};

export default ElmoaaserIconWithLogo;
