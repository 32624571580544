const LinkedinIcon = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.3857 12.8821C23.263 12.8821 21.295 12.2033 19.6893 11.0501V19.3714C19.6893 23.5275 16.3083 26.9076 12.1531 26.9076C10.5474 26.9076 9.0578 26.4015 7.83417 25.5421C5.89106 24.1776 4.61682 21.9209 4.61682 19.3714C4.61682 15.2152 7.99792 11.8341 12.1541 11.8341C12.4994 11.8341 12.8368 11.8629 13.1693 11.9076V12.8771V16.0875C12.8478 15.9873 12.5084 15.9288 12.1541 15.9288C10.2566 15.9288 8.71244 17.4729 8.71244 19.3714C8.71244 20.6933 9.4627 21.8415 10.5583 22.418C11.0356 22.6691 11.5785 22.813 12.1551 22.813C14.0089 22.813 15.5213 21.3383 15.5907 19.5004L15.5937 3.09111H19.6884C19.6884 3.44638 19.7231 3.79273 19.7846 4.13015C20.0734 5.69019 20.9993 7.02894 22.2825 7.86553C23.1756 8.44807 24.2415 8.78846 25.3847 8.78846L25.3857 12.8821Z"
        fill="white"
      />
    </svg>
  );
};

export default LinkedinIcon;
