/* eslint-disable react/jsx-no-target-blank */
import FacebookIcon from "../../assets/icons/socialMedia/facebook";
import InstagramIcon from "../../assets/icons/socialMedia/instagram";
import LinkedinIcon from "../../assets/icons/socialMedia/linkedin";
import YoutubeIcon from "../../assets/icons/socialMedia/youtube";
import TiktokIcon from "../../assets/icons/socialMedia/tiktok";

const SocialMedia = () => {
  const socialList = [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/ElMoasser.eg",
    },
    // {
    //   icon: <LinkedinIcon />,
    //   link: "",
    // },
    {
      icon: <InstagramIcon />,
      link: "https://www.instagram.com/elmoasserbooks",
    },
    {
      icon: <YoutubeIcon />,
      link: "https://youtube.com/@elmoasserbooks?si=nQ7Kvqi7GWmL0-Dj",
    },
    {
      icon: <TiktokIcon />,
      link: "https://www.tiktok.com/@elmoasserbooks",
    },
  ];
  return (
    <div
      className="absolute top-[40px] md:top-[100px] right-0-0 w-fit min-h-fit bg-primary px-3 py-4 flex flex-col gap-4 rounded-se-xl rounded-ee-xl animate__animated animate__fadeInRight"
    >
      {socialList.map((s,index) => (
        <a
          className=""
          href={s.link}
          target="_blank"
          key={index}
        >
          {s.icon}
        </a>
      ))}
    </div>
  );
};

export default SocialMedia;
