import Lottie from "react-lottie";
import lottieJson from "../../assets/images/bookidea.json";
import SocialMedia from "../socialMedia";
import ElmoaaserIconWithLogo from "../../assets/icons/elmoaaser-2";

const Header = () => {
  return (
    <div className="w-full relative overflow-x-hidden">
      <div className="container  flex flex-col md:flex-row gap-2 content-center items-center justify-center pt-10 pb-5">
        <div className="w-[222px] md:w-[276px] animate__animated animate__pulse">
          <Lottie
            options={{
              animationData: lottieJson,
              loop: true,
              autoplay: true,
            }}
          />{" "}
        </div>
        <div className="w-fit flex flex-col gap-y-1 animate__animated animate__pulse">
          <div className="text-[32px] font-bold leading-[61px] text-primary ">
            انضم الآن لأسرة{" "}
          </div>
          <div className="flex flex-row gap-x-2 rounded-[15px] items-center">
            <div className=" max-w-[225px] md:max-w-[350px] font-bold  text-white rounded-[15px]">
              <ElmoaaserIconWithLogo />
            </div>
            <div className="text-[18px] md:text-[24px] font-bold text-primary">
              <span className="block">فى اللغة </span>
              <span className="block">الإنجليزية</span>
            </div>
          </div>
          <div className="text-[20px] md:text-[30px] font-bold text-text-dark-3">
            وامنح ثقتك لمن يستحق
          </div>
        </div>
      </div>
      <SocialMedia />
    </div>
  );
};

export default Header;
