import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

export class ApiHandler {
  constructor(auth, contentType = "application/json") {
    this.baseUrl = API_URL;

    this.header = {
      Authorization: auth,
      "Content-Type": contentType,
      "x-api-key": X_API_KEY,
    };

    // Create the Axios instance with interceptor
    this.axios = axios.create({
      baseURL: this.baseUrl,
      headers: this.header,
    });

    // Add response interceptor
    this.axios.interceptors.response.use(
      (response) => {
        if (response.data && response.data.error === 401) {
          console.log("error", "401");

          // Clear cookies (assuming you have a cookie clearing function)
          this.clearCookies();

          // Navigate to login page (assuming you have a navigation function)
          this.navigateToLogin();
        }
        return response;
      },
      (error) => error
    );
  }

  async getConfig(endpoint) {
    const url = this.getFullUrl(endpoint);
    return this.handleResponse({ data: { url: url, header: this.header } });
  }

  async get(endpoint, token, params = {}) {
    const url = this.getFullUrl(endpoint, params);
    this.header.Authorization = `${token}`;

    const response = await this.axios.get(url, { headers: this.header });
    return this.handleResponse(response);
  }
  async getResponse(endpoint, token, params = {}) {
    const url = this.getFullUrl(endpoint, params);
    this.header.Authorization = `${token}`;

    const response = await this.axios.get(url, { headers: this.header });
    return response;
  }
  async getWithData(endpoint, token, data, params = {}) {
    const url = this.getFullUrl(endpoint, params);
    this.header.Authorization = `${token}`;

    const response = await this.axios.get(url, { headers: this.header },data);
    return this.handleResponse(response);
  }

  async post(endpoint, data, token = "") {
    const url = this.getFullUrl(endpoint);
    this.header.Authorization = `${token}`;
    const response = await this.axios.post(url, data, { headers: this.header });
    return this.handleResponse(response);
  }

  async postWithFile(endpoint, data, token = "") {
    const url = this.getFullUrl(endpoint);
    this.header.Authorization = `${token}`;
    const response = await this.axios.post(url, data, {
      headers: { ...this.header, "Content-Type": "multipart/form-data" },
    });
    return this.handleResponse(response);
  }

  async put(endpoint, data) {
    const url = this.getFullUrl(endpoint);
    const response = await this.axios.put(url, data, { headers: this.header });
    return this.handleResponse(response);
  }

  async delete(endpoint, data, token = "") {
    const url = this.getFullUrl(endpoint);
    this.header.Authorization = `${token}`;
    const response = await this.axios.delete(url, data, {
      headers: this.header,
    });
    return this.handleResponse(response);
  }

  getFullUrl(endpoint, params = {}) {
    const queryString = new URLSearchParams(params).toString();
    return `${this.baseUrl}${endpoint}?${queryString}`;
  }

  async handleResponse(response) {
    return response.data;
  }

  clearCookies() {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf("=");
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie =
        name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    }
  }

  navigateToLogin() {
    window.location.href = "/login";
    // Implement navigation to login page logic (consider using react-router-dom)
  }
}
