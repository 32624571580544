import { ApiHandler } from "./index";

const api = new ApiHandler();

const Api = {
  getGrades: async () => {
    try {
      const res = await api.getWithData("/getAllGrade");

      return res;
    } catch (error) {
      console.error("Error login:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
  addTeacher: async (data) => {
    try {
      const res = await api.post("/teacher/add", data);

      return res;
    } catch (error) {
      console.error("Error login:", error);

      // Handle errors appropriately (e.g., throw, return error object)
    }
  },
};

export default Api;
