import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import Api from "../../api/api";
import Loading from "../loading";
import Select, { components } from "react-select";
import CreatableSelect from "react-select/creatable";
import customStyles from "../../helpers/customSelect";
import directorates from "../../assets/directorates.json";
import MultiSelect from "./multiSelect";

const CustomOption = ({ innerRef, innerProps, isSelected, label }) => (
  <div
    ref={innerRef}
    {...innerProps}
    className="flex items-center p-2 hover:bg-[#DE494938] cursor-pointer"
  >
    <input
      type="checkbox"
      checked={isSelected}
      onChange={() => null} // Disable default onChange since React-Select will handle this
      className="form-checkbox text-primary mr-3"
    />
    <label className="text-text-dark-3 text-[0.875rem] px-2">{label}</label>
  </div>
);

const MainForm = ({ className, onHide, isAnimating, onGradeChange }) => {
  const [grades, setGrades] = useState([
    { id: 1, name: " الابتدائية" },
    { id: 2, name: " الإعدادية" },
    { id: 3, name: " الثانوية" },
  ]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [governorate, setGovernorate] = useState({});
  const [directorate, setDirectorate] = useState({});
  const [formData, setFormData] = useState({
    teahcer_name: "",
    teacher_phone: "",
    levels: [],
    governorate: "",
    directorate: "",
    school: "",
  });

  const [errors, setErrors] = useState({
    teahcer_name: "",
    teacher_phone: "",
    directorate: "",
    levels: "",
    governorate: "",
    school: "",
    school_type: "",
  });

  const handleChange = (event) => {
    if (event.target.name === "teacher_phone") {
      if (
        !/^[0-9]*$/.test(event.target.value) ||
        event.target.value.length > 11
      ) {
        return false;
      }
    }
    if (
      event.target.name == "teacher_phone" &&
      /^(011|012|010|015)\d{8}$/.test(event.target.value)
    ) {
      setErrors({ ...errors, teacher_phone: "" });
    }
    if (event.target.name != "teacher_phone") {
      setErrors({ ...errors, [event.target.name]: "" });
    }

    setFormData({ ...formData, [event.target.name]: event.target.value });
    // if (event.target.name === "levels") {
    //   onGradeChange(event.target.value);
    // }
  };

  const siteKey = process.env.REACT_APP_SITE_KEY;
  const recaptchaRef = useRef(null);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [captcha, setCaptcha] = useState("");

  const handleSubmit = (e) => {
    const newFormData = {
      ...formData,
      directorate: formData?.directorate?.name,
      governorate: formData?.governorate?.name,
      levels: JSON.stringify(formData?.levels?.map((level) => level.name)),
      school: formData?.school?.value || formData?.school?.name,
    };
    console.log(newFormData);
    e.preventDefault();
    // teacher_phone number validation

    if (
      !/^(011|012|010|015)\d{8}$/.test(formData.teacher_phone) ||
      formData.teahcer_name.length <= 3 ||
      !formData.directorate ||
      !formData.governorate ||
      !formData.school
    ) {
      toast.error(
        "الرجاء إكمال جميع البيانات والتأكد من اختيار كافة الخيارات المتاحة"
      );
      let newErrors = {};
      if (formData.teahcer_name.length <= 3) {
        newErrors.teahcer_name = "يجب أن يحتوى الاسم على أكثر من 3 أحرف.";
      }
      if (!/^(011|012|010|015)\d{8}$/.test(formData.teacher_phone)) {
        newErrors.teacher_phone = "الرجاء إدخال رقم هاتف صحيح.";
      }
      if (!formData.levels) {
        newErrors.levels = "الرجاء اختيار المراحل التعليمية.";
      }
      if (!formData.governorate) {
        newErrors.governorate = "الرجاء اختيار المحافظة.";
      }
      if (!formData.directorate) {
        newErrors.directorate = "الرجاء اختيار الإدارة التعليمية.";
      }
      if (!formData.school) {
        newErrors.school = "الرجاء اختيار اسم المدرسة.";
      }
      setErrors(newErrors);
      return;
    }

    Api.addTeacher({ ...newFormData })
      .then((res) => {
        if (res.status) {
          onHide();
        }
      })
      .catch(() => []);

    // window.grecaptcha.execute();
  };

  // useEffect(() => {
  //   setLoading(true);
  //   Api.getGrades()
  //     .then((res) => {
  //       setLoading(false);
  //       // console.log(res.data)
  //       setGrades(res.data);
  //     })
  //     .catch(() => {});
  // }, []);

  const submitCaptcha = (token) => {
    console.log(token);
  };

  const handleCreate = (inputValue) => {
    const newSchool = {
      label: inputValue,
      value: inputValue,
      name: inputValue,
    };
    setTimeout(() => {
      setDirectorate((prevDirectorate) => ({
        ...prevDirectorate,
        schools: prevDirectorate?.schools
          ? [...prevDirectorate.schools, newSchool]
          : [newSchool],
      }));
      setFormData({ ...formData, school: newSchool });
    }, 500);
  };

  return (
    <div
      className={`container px-5 py-5 ${className} animate__animated ${
        isAnimating ? "animate__fadeOutUp" : "animate__fadeInDown"
      }`}
    >
      <form
        className="bg-none md:bg-white flex flex-col rounded-xl md:gap-y-0 px-3 py-6 shadow-sm items-center"
        onSubmit={handleSubmit}
      >
        <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-3 pb-5">
          <div>
            <input
              type="text"
              placeholder="اسم المعلم"
              className=" bg-white-50 border border-text-dark-1 text-text-dark-3 text-sm rounded-lg focus:ring-primary focus:border-primary focus:outline-primary w-full p-2.5 "
              name="teahcer_name"
              value={formData.teahcer_name}
              onChange={handleChange}
            />
            {errors?.teahcer_name && (
              <p className="text-red-700 text-sm">{errors.teahcer_name}</p>
            )}
          </div>
          <div>
            <input
              type="tel"
              pattern="[0-9]*"
              placeholder="أدخل رقم الهاتف"
              dir="rtl"
              className="bg-white-50 border border-text-dark-1 text-text-dark-3 text-sm rounded-lg focus:ring-primary focus:border-primary focus:outline-primary w-full p-2.5"
              name="teacher_phone"
              value={formData.teacher_phone}
              onChange={handleChange}
            />
            {errors?.teacher_phone && (
              <p className="text-red-700 text-sm">{errors.teacher_phone}</p>
            )}
          </div>

          <div>
            <Select
              isMulti
              className="w-full my-auto"
              placeholder="اختر المراحل التعليمية"
              value={formData.levels}
              isRtl={true}
              id="levels"
              name="levels"
              options={grades}
              onChange={(newValue) => {
                setFormData({
                  ...formData,
                  levels: newValue,
                });
                onGradeChange(newValue);
                if (newValue?.length) setErrors({ ...errors, levels: "" });
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
              styles={customStyles}
              isClearable={true}
              isSearchable={true}
              menuPlacement="top"
              closeMenuOnSelect={false} // Prevent closing after selection
              components={{ Option: CustomOption }}
            />
            {errors?.levels && (
              <p className="text-red-700 text-sm">{errors.levels}</p>
            )}
          </div>
          <div>
            {" "}
            <Select
              className="w-full"
              placeholder="اختر المحافظة"
              value={formData.governorate}
              isRtl={true}
              id="governorate"
              name="governorate"
              options={directorates}
              onChange={(newValue) => {
                setFormData({
                  ...formData,
                  governorate: newValue,
                  directorate: "",
                  school: "",
                });
                setDirectorate({});
                setGovernorate(newValue);
                setErrors({ ...errors, governorate: "" });
              }}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.name}
              styles={customStyles}
              isClearable={true}
              isSearchable={true}
              menuPlacement="top"
            />
            {errors?.governorate && (
              <p className="text-red-700 text-sm">{errors.governorate}</p>
            )}
          </div>
          <div>
            <Select
              className="w-full"
              placeholder="اختر الإدارة التعليمية"
              value={formData.directorate}
              isRtl={true}
              id="directorate"
              name="directorate"
              options={governorate?.directorates}
              onChange={(newValue) => {
                setFormData({ ...formData, directorate: newValue, school: "" });
                setDirectorate(newValue);
                setErrors({ ...errors, directorate: "" });
              }}
              getOptionLabel={(option) => option?.name}
              getOptionValue={(option) => option?.name}
              styles={customStyles}
              isClearable={true}
              isSearchable={true}
              menuPlacement="top"
            />
            {errors?.directorate && (
              <p className="text-red-700 text-sm">{errors.directorate}</p>
            )}
          </div>
          <div>
            {" "}
            <CreatableSelect
              className="w-full"
              placeholder="أدخل أو اختر اسم المدرسة"
              value={formData.school}
              isRtl={true}
              id="school"
              name="school"
              isLoading={isLoading}
              options={directorate?.schools}
              onChange={(newValue) => {
                setFormData({ ...formData, school: newValue });
                setErrors({ ...errors, school: "" });
              }}
              onCreateOption={handleCreate}
              formatCreateLabel={(inputValue) => `إضافة "${inputValue}"`}
              getOptionLabel={(option) => option?.label || option?.name} // Display label
              getOptionValue={(option) => option?.value || option?.name}
              styles={customStyles}
              isClearable={true}
              menuPlacement="top"
              // isSearchable={true}
            />
            {errors?.school && (
              <p className="text-red-700 text-sm">{errors.school}</p>
            )}
          </div>
        </div>
        {/* <div
          id="recaptcha"
          className="g-recaptcha"
          data-size="invisible"
          data-sitekey={siteKey}
          data-callback={submitCaptcha}
          ref={recaptchaRef}
        /> */}
        <button
          className="px-5 py-3 w-full md:max-w-[400px] bg-primary text-white rounded-xl "
          type="submit"
        >
          اشترك الآن
        </button>
      </form>
      {/* <script
        src={`https://www.google.com/recaptcha/api.js?render=${siteKey}&async=defer`}
      /> */}
    </div>
  );
};

export default MainForm;
