const customStyles = {
  control: (provided, state) => ({
    ...provided,
    boxShadow: state.isFocused ? "0 0 0 0.5px #DE4949" : "0 0 0 0.20px #9FA1B0", // Adjusted border width
    borderColor: state.isFocused ? "#DE4949" : "#9FA1B0",
    borderRadius: "0.5rem",
    height: state.hasValue ? "auto" : "42px", // Dynamically adjust height if there are selected values
    maxHeight: "auto",
    ":hover": {
      boxShadow: state.isFocused
        ? "0 0 0 0.5px #DE4949"
        : "0 0 0 0.20px #9FA1B0", // Adjusted border width on hover
      borderColor: state.isFocused ? "#DE4949" : "#9FA1B0",
    },
    ":focus": {
      borderColor: "#DE4949",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#DE4949" : "",
    ":hover": {
      backgroundColor: state.isSelected ? "#DE4949" : "#DE494938",
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "0.875rem",
    ":focus": {
      borderColor: "#DE4949",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#9FA1B0",
    fontSize: "0.875rem",
  }),
  multiValue: (provided) => ({
    ...provided,
    maxWidth: "100%", // Ensure the selected items don't exceed the width of the container
    overflow: "hidden",
    textOverflow: "ellipsis",
  }),
};

export default customStyles;
